import { nextStep, setRequiresPermissions, updateClientType } from './clientRequestSlice';
import { Client } from '../App.types';
import { useAppDispatch } from '../../../configuration/setup/hooks';

interface DisplayedClientType {
    name: string;
    type: Client;
    disabled?: boolean;
}

const displayedClients: Array<DisplayedClientType> = [
    { name: 'Partner Client', type: 'Partner' },
    { name: 'SPA Client', type: 'SPA', disabled: false },
    { name: 'Backend Client', type: 'Backend', disabled: false },
];

export const Step0 = () => {
    const dispatch = useAppDispatch();

    const goToNextStep = (clientType: Client) => {
        if (clientType === 'Partner') {
            dispatch(setRequiresPermissions(true));
        } else {
            dispatch(setRequiresPermissions(false));
        }
        dispatch(updateClientType(clientType));
        dispatch(nextStep());
    };

    const renderButton = (title: string, clientType: Client, isDisabled: boolean | undefined, index: number) => (
        <p key={index}>
            <button
                className={'btn btn-default btn-block margin-bottom-20 padding-5'}
                onClick={() => goToNextStep(clientType)}
                disabled={isDisabled}
            >
                <span style={{ fontSize: 16, fontWeight: 600 }}>{title}</span>
            </button>
        </p>
    );

    return (
        <>
            <div className={'margin-bottom-25'}>
                <h4>Select Client Type</h4>
            </div>
            <div className={'center-block'} style={{ width: '250px' }}>
                {displayedClients.map((displayedClient, index) =>
                    renderButton(displayedClient.name, displayedClient.type, displayedClient.disabled, index)
                )}
            </div>
        </>
    );
};
