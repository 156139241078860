import { extractLanguage } from './lang/lang';
import { store } from './setup/store';
import { getLocale } from './lang/langSlice';

export const main = (renderApp: Function) => {
    // We want the `<html lang>` attribute to be synced with the
    // language currently displayed
    store.subscribe(() => {
        const lang = extractLanguage(getLocale(store.getState()));

        const html = document.querySelector('html');
        if (html && lang && html.getAttribute('lang') !== lang) {
            html.setAttribute('lang', lang);
        }
    });

    renderApp();
};
