import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../configuration/setup/store';

const initialState = {
    show: false,
};

const permissionCreatorSlice = createSlice({
    name: 'permissionCreator',
    initialState,
    reducers: {
        setShowPermissionCreateModal: (state, action: PayloadAction<boolean>) => {
            state.show = action.payload;
        },
        addPermission: (state) => {
            return state;
        },
    },
});

export const { setShowPermissionCreateModal, addPermission } = permissionCreatorSlice.actions;

export const getShow = (state: RootState) => state.permissionCreator.show;

export default permissionCreatorSlice.reducer;
