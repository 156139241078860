import { useEffect } from 'react';
import { getClientType, getCustomScopes, getShowCustomScopes } from './clientRequest.selectors';
import { setShowCustomScopes, updateCustomScopes } from './clientRequestSlice';
import { useAppDispatch, useAppSelector } from '../../../configuration/setup/hooks';

export const CustomScopeField = () => {
    const dispatch = useAppDispatch();

    const showCustomScopes = useAppSelector(getShowCustomScopes);
    const customScopes = useAppSelector(getCustomScopes);
    const clientType = useAppSelector(getClientType);

    useEffect(() => {
        if (clientType === 'SPA') {
            dispatch(setShowCustomScopes(true));
        }
    }, [dispatch, clientType]);

    return showCustomScopes ? (
        <div>
            <div className={'input-group col-lg-10'}>
                <span className={'input-group-btn'}>
                    <button
                        className={'btn btn-danger'}
                        onClick={() => {
                            dispatch(setShowCustomScopes(false));
                            dispatch(updateCustomScopes(''));
                        }}
                    >
                        Remove
                    </button>
                </span>
                <input
                    type={'text'}
                    className={'form-control'}
                    id={'customScopeField'}
                    placeholder={'Custom Scopes'}
                    value={customScopes}
                    onChange={(event) => dispatch(updateCustomScopes(event.target.value))}
                />
            </div>
            <label style={{ marginLeft: '80px' }}>Scopes separated by commas</label>
        </div>
    ) : (
        <div>
            <div className={'input-group'}>
                <span>
                    <button className={'btn btn-default'} onClick={() => dispatch(setShowCustomScopes(true))}>
                        Add Custom Scopes
                    </button>
                </span>
            </div>
            <label>&nbsp;</label>
        </div>
    );
};
