import { PropsWithChildren, useEffect, useState } from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { config } from '../config';
import { EVENT_USER_LANGUAGE_CHANGED, EVENT_USER_PROFILE_CHANGED } from '@rio-cloud/rio-user-menu-component';
import { oauthConfig } from './oidcConfig';

const RETRY_SIGNIN_TIMEOUT_IN_MS = 30000;

export const RioAuth = ({ children }: PropsWithChildren) => {
    const auth = useAuth();
    const [hasTriedSignin, setHasTriedSignin] = useState(false);

    // automatically sign-in
    useEffect(() => {
        if (
            !hasAuthParams() &&
            !auth.isAuthenticated &&
            !auth.activeNavigator &&
            !auth.isLoading &&
            !hasTriedSignin &&
            !config.login.mockAuthorization
        ) {
            // ToDo: store path, query and fragment before redirect
            auth.signinRedirect();
            setHasTriedSignin(true);
        }
    }, [auth, hasTriedSignin]);

    if (auth.isLoading) {
        return (
            <div className="height-100">
                <div className="spinnerInfoBox display-flex justify-content-center align-items-center height-100pct">
                    <div className="spinner">
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                    <div className="spinnerText">Loading</div>
                </div>
            </div>
        );
    }

    if (!auth.isAuthenticated) {
        return <div>Unable to log in</div>;
    }

    document.addEventListener(EVENT_USER_LANGUAGE_CHANGED, () => auth.signinSilent());
    document.addEventListener(EVENT_USER_PROFILE_CHANGED, () => auth.signinSilent());

    const retrySigninSilent = (error: Error) => {
        if (error.message === 'login_required') {
            oauthConfig.onSessionExpired();
        } else {
            setTimeout(
                () =>
                    auth.signinSilent().catch((err) => {
                        retrySigninSilent(err);
                    }),
                RETRY_SIGNIN_TIMEOUT_IN_MS
            );
        }
    };

    auth.events.addUserLoaded((user) => {
        oauthConfig.onSessionRenewed(user);
    });

    auth.events.addUserUnloaded(() => {
        oauthConfig.onSessionExpired();
    });

    auth.events.addSilentRenewError((error) => {
        console.log('Silent renew error', error);
        oauthConfig.onSessionExpired();
    });

    auth.events.addUserSignedOut(() => {
        oauthConfig.onSessionExpired();
    });

    return children;
};
