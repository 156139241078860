import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { config } from '../../config';
import App from './App';
import { getSessionExpiredAcknowledged, hideSessionExpiredDialog } from './appSlice';
import { DisplayMessages, getDisplayMessages, getLocale } from '../../configuration/lang/langSlice';
import { isUserSessionExpired } from '../../configuration/login/loginSlice';
import messagesEN from '../translations/en-GB.json';
import { getIdToken } from '../../configuration/tokenHandling/tokenSlice';
import { RootState } from '../../configuration/setup/store';

export interface AppPropertiesFromDispatch {
    hideSessionDialog: () => void;
}

export interface AppPropertiesFromState {
    homeRoute: string;
    displayMessages: DisplayMessages;
    showSessionExpired: boolean | undefined;
    userLocale: string;
    idToken: string | undefined;
}

const mapDispatchToProps = (dispatch: Dispatch): AppPropertiesFromDispatch => ({
    hideSessionDialog: () => dispatch(hideSessionExpiredDialog()),
});

const mapStateToProps = (state: RootState): AppPropertiesFromState => ({
    homeRoute: config.homeRoute as string,
    displayMessages: getDisplayMessages(state) ? (getDisplayMessages(state) as DisplayMessages) : messagesEN,
    showSessionExpired: isUserSessionExpired(state) && !getSessionExpiredAcknowledged(state),
    userLocale: getLocale(state) ? (getLocale(state) as string) : 'en-GB',
    idToken: getIdToken(state)?.rawValue as any,
});

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

export default AppContainer;
