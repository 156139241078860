import { getRequiresPermissions } from './clientRequest.selectors';
import { setShowPermissionCreateModal } from '../permissionModal/permissionCreatorSlice';
import { useAppDispatch, useAppSelector } from '../../../configuration/setup/hooks';

export const CustomPermissions = () => {
    const dispatch = useAppDispatch();

    const requiresPermissions = useAppSelector(getRequiresPermissions);

    return requiresPermissions ? (
        <div>
            <button
                className={'btn btn-default margin-bottom-10'}
                onClick={() => dispatch(setShowPermissionCreateModal(true))}
                disabled={true}
            >
                Add Custom Permissions
            </button>
        </div>
    ) : null;
};
