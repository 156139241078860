import { ApiSpec, Audience, ReadOrWrite, RepoApiSpec } from '../App.types';

export enum Api {
    Useradmin = 'useradmin',
    Assets = 'asset-administration',
    IotEvents = 'iot-event-processor',
    DrivingTime = 'drivingtime',
    Tacho = 'tachograph-services',
    Maintenance = 'servicecare',
    DriverAdmin = 'driver-administration',
    IotEventOutboundFeed = 'iot-events',
    Perform = 'perform',
}

export const getApiFromString = (apiName: string) => {
    switch (apiName) {
        case 'useradmin':
            return Api.Useradmin;
        case 'asset-administration':
            return Api.Assets;
        case 'iot-event-processor':
            return Api.IotEvents;
        case 'drivingtime':
            return Api.DrivingTime;
        case 'tachograph-services':
            return Api.Tacho;
        case 'servicecare':
            return Api.Maintenance;
        case 'driver-administration':
            return Api.DriverAdmin;
        case 'iot-events':
            return Api.IotEventOutboundFeed;
        case 'perform':
            return Api.Perform;
        default:
            return undefined;
    }
};

const knownApis: Array<ApiSpec> = [
    {
        name: Api.Useradmin,
        scopes: [
            { accessType: 'read', name: 'user.read' },
            { accessType: 'write', name: 'user.write' },
        ],
        audience: 'component-internal',
    },
    {
        name: Api.Assets,
        scopes: [
            { accessType: 'read', name: 'asset-administration.read' },
            { accessType: 'write', name: 'asset-administration.write' },
        ],
        permissions: [
            { accessType: 'read', name: 'asset.read' },
            { accessType: 'write', name: 'asset.write' },
        ],
        audience: 'external-partner',
    },
    {
        name: Api.IotEvents,
        scopes: [{ accessType: 'write', name: 'iot-event-processor.write' }],
        audience: 'external-partner',
    },
    {
        name: Api.DrivingTime,
        scopes: [{ accessType: 'read', name: 'driving-time.read' }],
        permissions: [{ accessType: 'read', name: 'driving-time.read' }],
        audience: 'external-partner',
    },
    {
        name: Api.Tacho,
        scopes: [{ accessType: 'read', name: 'tachograph-partner.read' }],
        permissions: [
            { accessType: 'read', name: 'file-list.read' },
            { accessType: 'read', name: 'file-data.read' },
        ],
        audience: 'external-partner',
        legacy: true,
    },
    {
        name: Api.Maintenance,
        scopes: [
            { accessType: 'read', name: 'vehiclestate.read' },
            { accessType: 'write', name: 'vehiclestate.write' },
        ],
        permissions: [
            { accessType: 'read', name: 'read-write' },
            { accessType: 'write', name: 'read-write' },
        ],
        audience: 'external-partner',
    },
    {
        name: Api.DriverAdmin,
        scopes: [
            { accessType: 'read', name: 'drivers.read' },
            { accessType: 'write', name: 'drivers.write' },
        ],
        permissions: [
            { accessType: 'read', name: 'driver.read' },
            { accessType: 'write', name: 'driver.create' },
            { accessType: 'write', name: 'driver.update' },
            { accessType: 'write', name: 'driver.delete' },
        ],
        audience: 'external-partner',
    },
    {
        name: Api.IotEventOutboundFeed,
        scopes: [{ accessType: 'read', name: 'iot-events.read' }],
        permissions: [{ accessType: 'read', name: 'iot-events.read' }],
        audience: 'external-partner',
    },
    {
        name: Api.Perform,
        scopes: [
            { accessType: 'read', name: 'perform.read' },
            { accessType: 'read', name: 'operating-conditions.read' },
            { accessType: 'read', name: 'operating-conditions.write' },
            { accessType: 'read', name: 'perform.partner-api.read' },
        ],
        additionalRequiredApis: [
            { api: Api.Assets, accessType: ['read'] },
            { api: Api.DriverAdmin, accessType: ['read'] },
        ],
        audience: 'external-partner',
    },
];

const onlyUnique = (value: any, index: any, self: any) => self.indexOf(value) === index;

const getApiSpecByName = (apiName: Api, type: Array<ReadOrWrite>): RepoApiSpec | undefined => {
    const selectedApi = knownApis.find((api) => api.name === apiName);
    if (selectedApi === undefined) {
        return;
    }
    return {
        name: selectedApi.name,
        scopes: selectedApi.scopes
            .filter((scope) => type.includes(scope.accessType))
            .map((filteredScope) => filteredScope.name),
        permissions: selectedApi.permissions
            ? selectedApi.permissions
                  .filter((perm) => type.includes(perm.accessType))
                  .map((filteredPerm) => filteredPerm.name)
            : undefined,
        additionalRequiredApis: selectedApi.additionalRequiredApis,
    };
};

const getAvailableAccessTypes = (apiName: Api) => {
    const selectedApi = knownApis.find((api) => api.name === apiName);
    if (selectedApi === undefined) return;
    return selectedApi.scopes.map((scope) => scope.accessType).filter(onlyUnique);
};

const getApiSpecByAudience = (audience: Audience) =>
    knownApis
        .filter((api) => api.audience === audience)
        .map((api) => ({
            name: api.name,
        }));

export const ApiRepository = {
    getApiSpecByName,
    getApiSpecByAudience,
    getAvailableAccessTypes,
};
