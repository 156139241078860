import { useSelector } from 'react-redux';

import { Step0 } from './Step0';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';

import { getCurrentStep } from './clientRequest.selectors';

export const ClientRequest = () => {
    const currentStep = useSelector(getCurrentStep);
    const displayedContent = () => {
        switch (currentStep) {
            case 0:
                return <Step0 />;
            case 1:
                return <Step1 />;
            case 2:
                return <Step2 />;
            case 3:
                return <Step3 />;
            default:
                return null;
        }
    };
    return (
        <div className={'intro container'}>
            <div className={'row'}>
                <div className={'margin-left-10 col-xs-12'}>
                    <h3>Build a Client Request</h3>
                </div>
            </div>
            <div className={'col-xs-12 col-md-6'}>
                <div className={'panel panel-default'}>
                    <div className={'panel-body'}>{displayedContent()}</div>
                </div>
            </div>
        </div>
    );
};
