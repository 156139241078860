import { config } from '../config';
import { AuthProvider } from 'react-oidc-context';
import { oidcConfig } from './oidcConfig';
import { PropsWithChildren } from 'react';
import { RioAuth } from './RioAuth';

export const RioAuthProvider = ({ children }: PropsWithChildren) =>
    config.login.mockAuthorization ? (
        children
    ) : (
        <AuthProvider {...oidcConfig}>
            <RioAuth>{children}</RioAuth>
        </AuthProvider>
    );
