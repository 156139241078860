import { NavLink, Route, Routes } from 'react-router-dom';

import { DefaultUserMenu } from '@rio-cloud/rio-user-menu-component';
import { SessionExpiredDialog } from '@rio-cloud/rio-session-expired-info';

import ActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import ApplicationHeader from '@rio-cloud/rio-uikit/ApplicationHeader';
import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';

import { config } from '../../config';
import { AppPropertiesFromDispatch, AppPropertiesFromState } from './App.container';
import { ClientRequest } from './clientRequestHelper/ClientRequest';
import { PermissionCreator } from './permissionModal/PermissionCreator';
import IframeResizer from 'iframe-resizer-react';
import { RioNotifications } from '@rio-cloud/rio-notifications-component';
import DefaultRedirect from './routes/DefaultRedirect';
import { DEFAULT_ROUTE } from './routes/routes';

type AppProperties = AppPropertiesFromDispatch & AppPropertiesFromState;

const App = (props: AppProperties) => {
    const { hideSessionDialog, homeRoute, displayMessages, showSessionExpired, userLocale, idToken } = props;

    const noIframes = checkNoIframesQueryString();

    if (!displayMessages) {
        return null;
    }

    const navItems = [
        {
            key: 'clientCreator',
            route: <NavLink to={'/request'}>Request Builder</NavLink>,
        },
    ];

    const appTitle = 'Client Helper';
    const environment = import.meta.env.PROD ? 'production' : 'local';

    const userMenu = <DefaultUserMenu environment={environment} locale={userLocale} />;
    const notifications = <RioNotifications />;

    const menuUrl = config.backend.MENU_SERVICE as string;
    const appNavigator = noIframes ? undefined : <IframeResizer className={'iFrameResizer'} src={menuUrl} />;

    const CustomLogoutComponent = (
        <ActionBarItem hidePopoverOnClick={false}>
            <ActionBarItem.Icon>
                <span className="icon rioglyph rioglyph-question-sign" />
            </ActionBarItem.Icon>
            <ActionBarItem.Popover>
                <ActionBarItem.List>
                    <ActionBarItem.ListItem className="padding-0">
                        <button
                            className={'btn btn-link btn-link-inline text-color-dark'}
                            onClick={() => {
                                window.location.href = `https://auth.iam.rio.cloud/logout?id_token_hint=${idToken}`;
                            }}
                            disabled={!idToken}
                        >
                            <span className={'rioglyph rioglyph-off margin-right-5 text-size-16 text-color-dark'} />
                            <span>Logout</span>
                        </button>
                    </ActionBarItem.ListItem>
                </ActionBarItem.List>
            </ActionBarItem.Popover>
        </ActionBarItem>
    );

    // eslint-disable-next-line jsx-a11y/anchor-has-content
    const homeLink = <a href={homeRoute} />;

    const actionBarItems = noIframes ? [CustomLogoutComponent] : [CustomLogoutComponent, notifications, userMenu];

    return (
        <ApplicationLayout className={'ClientHelperWeb'}>
            <ApplicationLayout.Header>
                <ApplicationHeader
                    label={appTitle}
                    appNavigator={appNavigator}
                    homeRoute={homeLink}
                    navItems={navItems}
                    actionBarItems={actionBarItems}
                />
            </ApplicationLayout.Header>
            <ApplicationLayout.Body>
                <NotificationsContainer />
                <SessionExpiredDialog locale={userLocale} onClose={hideSessionDialog} show={!!showSessionExpired} />
                <PermissionCreator />
                <Routes>
                    <Route path={DEFAULT_ROUTE} element={<ClientRequest />} />
                    <Route path="*" element={<DefaultRedirect />} />
                </Routes>
            </ApplicationLayout.Body>
        </ApplicationLayout>
    );
};

const checkNoIframesQueryString = () => {
    const href = window.location.href;
    if (href.includes('?')) {
        const paramsString = href.split('?')[1];
        const searchParams = new URLSearchParams(paramsString);
        return searchParams.get('template') === 'no_iframes';
    }
    return false;
};

export default App;
