import { ReactNode } from 'react';
import ToggleButton from '@rio-cloud/rio-uikit/ToggleButton';

// ToggleButton component does not give disable option. This is a quick workaround
export const ToggleButtonExtended = (props: {
    disabled: boolean;
    active: boolean;
    onClick: any;
    children?: ReactNode;
}) =>
    props.disabled ? (
        <ToggleButton active={props.active} onClick={props.onClick}>
            {props.children}
        </ToggleButton>
    ) : (
        <button className={'btn btn-default'} disabled={true}>
            {props.children}
        </button>
    );
