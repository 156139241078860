import Dialog from '@rio-cloud/rio-uikit/Dialog';
import { connect } from 'react-redux';
import { getShow, setShowPermissionCreateModal } from './permissionCreatorSlice';
import { RootState } from '../../../configuration/setup/store';

interface StateProps {
    show: boolean;
}

interface DispatchProps {
    setShowModal: (show: boolean) => void;
}

const PermissionCreatorRaw = ({ show, setShowModal }: StateProps & DispatchProps) => {
    const renderBody = () => (
        <h1>
            <span className={'rioglyph rioglyph-truck-breakdown text-color-gray'} />
            Under Construction
            <span className={'rioglyph rioglyph-truck-breakdown text-color-gray'} />
        </h1>
    );

    const renderFooter = () => (
        <div className={'btn-toolbar pull-right'}>
            <button className={'btn btn-primary'} disabled>
                Save
            </button>
            <button className={'btn btn-primary'} onClick={() => setShowModal(false)}>
                Cancel
            </button>
        </div>
    );

    return (
        <Dialog
            show={show}
            body={renderBody()}
            footer={renderFooter()}
            bsSize={Dialog.SIZE_LG}
            onHide={() => setShowModal(false)}
            showCloseButton={true}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    show: getShow(state),
});

const mapDispatchToProps = {
    setShowModal: setShowPermissionCreateModal,
};

export const PermissionCreator = connect(mapStateToProps, mapDispatchToProps)(PermissionCreatorRaw);
