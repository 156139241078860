import { combineReducers, configureStore } from '@reduxjs/toolkit';

import configReducer from './configSlice';
import loginReducer from '../login/loginSlice';
import langReducer from '../lang/langSlice';
import { clientRequestReducer } from '../../features/app/clientRequestHelper/clientRequestSlice';
import permissionCreatorReducer from '../../features/app/permissionModal/permissionCreatorSlice';
import tokenHandlingReducer from '../tokenHandling/tokenSlice';
import appReducer from '../../features/app/appSlice';

export const rootReducer = combineReducers({
    config: configReducer,
    lang: langReducer,
    app: appReducer,
    clientRequest: clientRequestReducer,
    permissionCreator: permissionCreatorReducer,
    login: loginReducer,
    tokenHandling: tokenHandlingReducer,
});

export const setupStore = (preloadedState?: Partial<RootState>) =>
    configureStore({
        reducer: rootReducer,

        // Adding the api middleware enables caching, invalidation, polling,
        // and other useful features of `rtk-query`.
        middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
        preloadedState,
    });

export const store = setupStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = AppStore['dispatch'];
